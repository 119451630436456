<template>
  <el-popover
    placement="bottom-end"
    width="464"
    popper-class="my-booking"
    :offset="13"
    trigger="click">
    <div v-if="list.length === 0" class="my-booking-tip">
      {{ typeData.tip }}
    </div>
    <template v-else>
      <div
        v-for="(item, index) in list"
        :key="index"
        class="my-booking-item"
        @click="selectData(item)"
      >
        {{ item.value || item }}
      </div>
    </template>
    <div ref="button" slot="reference" class="my-booking-attribute" @click="typeData.click">
      <en-icon :name="typeData.icon" size="small"></en-icon>{{ typeData.name }}
    </div>
  </el-popover>
</template>

<script>
import { updateFlightInfoShowValue } from "../tools";

export default {
  name: "my-booking",
  props: {
    carData: {
      type: Object
    },
    type: {
      type: String,
      default: "flight"
    }
  },
  data() {
    return {
      list: []
    };
  },
  computed: {
    typeData() {
      return this.type === "flight" ? {
        icon: "jipiaoyuding-xianxing",
        name: "我的航班",
        click: this.showMyFlight,
        tip: "抱歉，您暂无航班行程"
      } : {
        icon: "wodejiudian-yongcheyuding",
        name: "我的酒店",
        click: this.showMyHotel,
        tip: "抱歉，您还没有预定酒店"
      };
    }
  },
  methods: {
    /**
     * 选中数据
     * @param itemData 数据
     */
    selectData(itemData) {
      this.$emit("selectData", itemData);
      this.$refs.button.click();
    },
    /**
     * 显示我的航班信息，没接口
     */
    showMyFlight() {
      this.list = updateFlightInfoShowValue([]);
    },
    showMyHotel() {
      // this.list = [{ value: "2020-8-19 / 2020-8-20 佳兴世尊酒店" }];
    }
  }
};
</script>

<style lang="scss" scoped>
  .my-booking-attribute {
    position: absolute;
    right: 12px;
    cursor: pointer;
    top: 6px;
    border-left: 1px solid #3e90fe;
    font-size: 12px;
    line-height: 18px;
    padding: 3.5px 0 3.5px 10px;
    display: flex;
    justify-content: flex-start;
    color: #3e90fe;

    .en-icon {
      margin-right: 8px;
    }
  }
.my-booking {
  .my-booking-tip,
  .my-booking-item {
    margin: -12px;
    padding-left: 12px;
    height: 36px;
    line-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: #333333;
  }
  .my-booking-item {
    &:hover {
      background-color: #f5f8fc;
      color: #3e90fe;
    }
  }
}

</style>
