var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "en-personal" },
    [
      _c(
        "div",
        { staticClass: "en-personal-container" },
        [
          _vm.tipsLabel !== ""
            ? _c("div", { staticClass: "tips-label" }, [
                _vm._v(" " + _vm._s(_vm.tipsLabel) + " "),
              ])
            : _vm._e(),
          _c("el-input", {
            class: { "with-tips": _vm.tipsLabel !== "" },
            attrs: { readonly: "readonly", placeholder: _vm.placeholder },
            on: { focus: _vm.focus },
            model: {
              value: _vm.value.name,
              callback: function ($$v) {
                _vm.$set(_vm.value, "name", $$v)
              },
              expression: "value.name",
            },
          }),
        ],
        1
      ),
      _vm.contactShow
        ? _c("contact", {
            attrs: { "dialog-visible": _vm.contactShow },
            on: {
              close: function ($event) {
                _vm.contactShow = false
              },
              complete: _vm.complete,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }