var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "contact",
      attrs: {
        title: "选择人员",
        "show-close": false,
        visible: _vm.dialogVisible,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "contact-body-contain" }, [
        _c("span", { staticClass: "title" }, [_vm._v("选择人员")]),
        _c(
          "div",
          { on: { click: _vm.close } },
          [
            _c("en-icon", {
              staticClass: "close-icon",
              style: { color: "#A9B5C6" },
              attrs: { name: "guanbi-danchuang", size: "14px" },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "content-contain" },
          [
            _c(
              "el-tabs",
              {
                model: {
                  value: _vm.activeTab,
                  callback: function ($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab",
                },
              },
              [
                _c("el-tab-pane", { attrs: { label: "常用", name: "first" } }, [
                  _c(
                    "div",
                    { staticClass: "often-contain" },
                    [
                      _c("el-input", {
                        staticClass: "contact-search",
                        attrs: {
                          size: "mini",
                          placeholder: "搜索关键字",
                          "suffix-icon": "el-icon-search",
                        },
                        on: { change: _vm.searchPersonDatas },
                        model: {
                          value: _vm.keyword,
                          callback: function ($$v) {
                            _vm.keyword = $$v
                          },
                          expression: "keyword",
                        },
                      }),
                      _vm.showHistoryData
                        ? _c("div", { staticClass: "section-title" }, [
                            _vm._v(" 选择历史 "),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "en-loading",
                              rawName: "v-en-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          staticClass: "person-content",
                        },
                        [
                          _vm._l(_vm.datas, function (item, index) {
                            return [
                              _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "person-content-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.click(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "user-Infos" },
                                    [
                                      _c("en-user-logo", {
                                        attrs: {
                                          "user-name": item.name,
                                          size: "30",
                                        },
                                      }),
                                      _c("div", { staticClass: "name" }, [
                                        _vm._v(_vm._s(item.name)),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "mobile" }, [
                                    _vm._v(" " + _vm._s(item.mobile) + " "),
                                  ]),
                                ]
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "新增", name: "second" } },
                  [
                    _c(
                      "div",
                      { staticClass: "add-contain" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "form",
                            attrs: {
                              model: _vm.form,
                              rules: _vm.rules,
                              "label-width": "80px",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "姓名", prop: "name" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入姓名",
                                  },
                                  model: {
                                    value: _vm.form.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "name", $$v)
                                    },
                                    expression: "form.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "手机号码", prop: "mobile" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入手机号码",
                                  },
                                  model: {
                                    value: _vm.form.mobile,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "mobile", $$v)
                                    },
                                    expression: "form.mobile",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "en-button",
                              {
                                staticClass: "confirm-button",
                                attrs: { size: "small", type: "blue" },
                                on: { click: _vm.complete },
                              },
                              [_vm._v(" 确定 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }