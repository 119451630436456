<template>
  <div class="car-airport-tab">
    <el-amap :vid="'amap-vue'" :plugin="['Autocomplete', 'PlaceSearch']" ref="map" />
    <div class="car-form-item">
      <en-flight
        v-model="carData.flightInfo"
        :car-data="carData"
        @change="flightChange"
        placeholder="请选择航班号"
        tips-label="航班号"
        :show-right="showMine"
      >
      </en-flight>
    </div>
    <div class="car-form-item">
      <div class="tips-label">
        用车时间
      </div>
      <el-select
        v-if="isJieji"
        v-model="carData.useTime"
        @change="change"
        placeholder="请选择用车时间"
        class="en-select"
        value-key="value"
      >
        <el-option
          v-for="item in timeOptions"
          :key="item.value"
          :value="item.value"
          :label="item.name"
        >
        </el-option>
      </el-select>
      <el-date-picker
        v-else
        class="en-time"
        v-model="carData.useTime"
        type="datetime"
        format="yyyy-MM-dd HH:mm"
        value-format="yyyy-MM-dd HH:mm"
        :clearable="false"
        @change="change"
        placeholder="请选择用车时间">
      </el-date-picker>
    </div>
    <div class="car-form-item">
      <en-address
        v-model="carData.fromLocationInfo"
        tips-label="出发地"
        placeholder="请选择出发地"
        :show-right="!isJieji && showMine"
        @input="change"
      >
      </en-address>
    </div>
    <div class="car-form-item">
      <en-address
        v-model="carData.toLocationInfo"
        tips-label="目的地"
        placeholder="请选择目的地"
        :show-right="isJieji && showMine"
        @input="change"
      >
      </en-address>
    </div>
    <div class="car-form-item">
      <en-personal
        v-model="carData.usePersonInfo"
        @input="personalChange"
        tips-label="乘车人员"
        placeholder="请选择乘车人"
      >
      </en-personal>
    </div>
    <div class="car-form-item" style="margin-bottom: 0">
      <div class="tips-label">
        选择车型
      </div>
      <el-select
        v-model="carData.carType"
        @change="change"
        placeholder="请选择车型"
        class="en-select"
      >
        <el-option
          v-for="item in carTypeOptions"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="car-form-item" style="margin-bottom: 0">
      <car-price :loading="loading" :price="carData.price" :price-detail="priceDetail"></car-price>
    </div>
    <div class="car-form-item">
      <en-button class="search-button" @click="complete" :disabled="loading">
        立即预订
      </en-button>
    </div>
  </div>
</template>

<script>
import EnAddress from "./en-address";
import EnPersonal from "./en-personal";
import EnFlight from "./en-flight";
import carPrice from "./car-price";
import { CarData } from "../carData";
import { getCarTypeOptions, getTimeOptions } from "../tools";

export default {
  name: "carAirportTab",
  components: {
    EnAddress,
    EnPersonal,
    EnFlight,
    carPrice
  },
  props: {
    isJieji: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.carData = new CarData(this.isJieji);
    this.carTypeOptions = getCarTypeOptions();
    this.timeOptions = getTimeOptions();
  },
  data() {
    return {
      carData: null,
      loading: false,
      carTypeOptions: null,
      timeOptions: null,
      // 显示我的酒店和我的航班，暂时屏蔽
      showMine: false
    };
  },
  computed: {
    // 价格详情
    priceDetail() {
      return this.carData?.priceInfo?.priceDetail;
    }
  },
  methods: {
    // 航班数据变化
    flightChange(data) {
      this.carData.flightInfo = data;
      this.change();
      // eslint-disable-next-line no-undef
      this.carData?.updateFlightAddressAndTime(AMap, () => {
        this.change();
      });
    },
    // 人员数据变化
    personalChange(data) {
      this.carData.usePersonInfo = data;
      this.change();
    },
    // 数据变更，重新预估价格
    change() {
      this.carData?.againEstimatedPrice(() => { this.loading = true; }, () => { this.loading = false; });
    },
    // 预定，父组件实现
    complete() {
      this.$emit("complete", this.carData);
    }
  }
};
</script>

<style lang="scss" scoped>

.car-airport-tab {
  height: 100%;
  margin-top: 2px;
  max-height: 600px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .en-time {
    width: 100%;
    line-height: 36px;
    height: 36px;

    ::v-deep .el-input__inner {
      padding-left: 72px;
    }
    ::v-deep .el-input__prefix {
      display: none;
    }
  }
  .car-form-item {
    margin-bottom: 16px;
    position: relative;

    &:nth-last-child(1) {
      margin-bottom: 30px;
    }
    .tips-label {
      position: absolute;
      left: 12px;
      line-height: 12px;
      top: 13px;
      z-index: 1;
      font-weight: 400;
      font-size: 12px;
      color: #91a1b7;
    }
    .en-select {
      width: 100%;
      line-height: 36px;
      height: 36px;

      ::v-deep .el-input {
        line-height: 36px;
        height: 36px;
        width: 100%;

        input {
          height: 36px;
          padding: 0 72px;
          color: #333333;
        }
      }
    }

    .search-button {
      width: 100%;
      font-size: 16px;
      padding: 9px 15px;
    }
  }
}

</style>
