<template>
  <div class="car-price">
    <div class="car-price-content" @click="hasPriceDetail ? open = !open : ''">
      <div class="price">
        <span style="color: #3e90fe;">价格预估</span>
        <span style="margin-left: 11px;color: #B9C4D2;">约</span>
        <el-button type="text" :loading="loading" style="margin-left: 6px;"></el-button>
        <span v-if="!loading" style="color: #F88529;">￥<span style="color: #F88529;font-size: 16px">{{ price }}</span></span>
        <en-icon v-if="hasPriceDetail" :name="open ? 'zhankai1' : 'shouqi'"></en-icon>
      </div>
    </div>
    <div v-if="open && hasPriceDetail" class="car-price-detail">
      <div
        v-for="(item, index) in detailPrices"
        class="car-price-detail-item"
        :key="index"
      >
        <div class="name">{{ item.name }}</div>
        <div class="price">￥{{ (item.price || 0).toFixed(2) }}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "car-price",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    price: {
      type: [Number, String],
      default: "0.00"
    },
    priceDetail: {
      type: Object
    }
  },
  data() {
    return {
      open: false
    };
  },
  computed: {
    hasPriceDetail() {
      return !this.loading && this.priceDetail;
    },
    detailPrices() {
      return [
        { name: "里程费", price: this.priceDetail.distanceFee },
        { name: "时长费", price: this.priceDetail.extraTimeFee },
        { name: "夜间服务费", price: this.priceDetail.nightAmount },
        { name: "停车费", price: this.priceDetail.parkingFee },
        { name: "高速费", price: this.priceDetail.highwayFee }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>

.car-price {
  ::v-deep .el-icon-loading {
    color: rgba(0, 0, 0, 0.3);
    font-size: 22px;
  }
  .car-price-content {
    height: 60px;
    line-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .price {
      font-size: 12px;
      display: flex;
      align-items: center;
      .en-icon {
        position: absolute;
        right: 6px;
        width:9px;
        height:5px;
        color: #A9B5C6;
      }
    }
  }
  .car-price-detail {
    height: 176px;
    background-color: #F5F7FA;
    border: 1px solid #E8ECF2;
    border-radius:4px;
    padding-top: 20px;
    .car-price-detail-item {
      display: flex;
      height: 12px;
      align-items: center;
      justify-content: space-between;
      padding-left: 12px;
      padding-right: 12px;
      color: #636C78;
      font-size:12px;
      margin-bottom: 20px;
    }
    margin-bottom: 16px;
  }
}

</style>
