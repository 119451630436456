import enplusCity from "enplusCity";
import airport from "@/assets/js/huoliAirportV1.json";

// 滴滴城市
export const didiCityData = enplusCity.filter((item) => item.partnerCode === "C01");
export const huoliCityData = enplusCity.filter((item) => item.partnerCode === "C02");
// 机票城市
export const ticketCityData = enplusCity.filter((item) => item.partnerCode === "P01");
// 酒店城市
export const hotelCityData = enplusCity.filter((item) => item.partnerCode === "H02");
// 伙力机场
export const airportData = airport;
// 过滤伙力机场数据
export function filterAirPortData(code) {
  return airport.filter((item) => item.ariPortSzm === code);
}
