var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "en-flight" },
    [
      _c(
        "div",
        { staticClass: "en-flight-container" },
        [
          _vm.tipsLabel !== ""
            ? _c("div", { staticClass: "tips-label" }, [
                _vm._v(" " + _vm._s(_vm.tipsLabel) + " "),
              ])
            : _vm._e(),
          _c("el-input", {
            class: {
              "with-tips": _vm.tipsLabel !== "",
              "show-right": _vm.showRight,
            },
            attrs: { readonly: "readonly", placeholder: _vm.placeholder },
            nativeOn: {
              click: function ($event) {
                return _vm.clickQueryInfo.apply(null, arguments)
              },
            },
            model: {
              value: _vm.input,
              callback: function ($$v) {
                _vm.input = $$v
              },
              expression: "input",
            },
          }),
          _vm.showRight
            ? _c("myBooking", {
                attrs: { type: "flight" },
                on: { selectData: _vm.updateValue },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.showDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "航班信息",
                width: "700px",
                visible: _vm.showDialog,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showDialog = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    "label-position": "right",
                    "label-width": "60px",
                    model: _vm.flightForm,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "起飞时间", prop: "date" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择起飞时间" },
                          model: {
                            value: _vm.flightForm.date,
                            callback: function ($$v) {
                              _vm.$set(_vm.flightForm, "date", $$v)
                            },
                            expression: "flightForm.date",
                          },
                        },
                        _vm._l(_vm.dateList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "航班号", prop: "flight" } },
                    [
                      _c("el-autocomplete", {
                        attrs: {
                          placeholder:
                            "请输入航班号，字母不区分大小写，如MU5569",
                          "fetch-suggestions": _vm.searchFlightInfo,
                          "trigger-on-focus": false,
                        },
                        on: { select: _vm.selectFlight },
                        model: {
                          value: _vm.flightForm.keywords,
                          callback: function ($$v) {
                            _vm.$set(_vm.flightForm, "keywords", $$v)
                          },
                          expression: "flightForm.keywords",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("en-button", { on: { click: _vm.confirmFlight } }, [
                    _vm._v(" 确定 "),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }