<template>
  <div class="car-travel">
    <overStandardDialog
      v-if="overStandardVisible"
      :dialog-visible.sync="overStandardVisible"
      @getOverStandardMemo="exceedStandardHandle"
    ></overStandardDialog>
    <ENitinerary
      v-if="dialogVisible"
      :dialog-visible.sync="dialogVisible"
      :onlyChooseItinerary="false"
      :isNewNode="false"
      @selectCreateNewTrip="handle"
      @selectTripData="handle"></ENitinerary>
    <div class="car-form">
      <div class="car-main">
        <el-tabs v-model="tabItem">
          <el-tab-pane label="接机" name="first">
            <carAirportTab :is-jieji="true" @complete="complete"></carAirportTab>
          </el-tab-pane>
          <el-tab-pane label="送机" name="second">
            <carAirportTab :is-jieji="false" @complete="complete"></carAirportTab>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import CarAirportTab from "./components/carAirportTab";
import ENitinerary from "../components/en-itinerary";
import overStandardDialog from "../hotel/components/over-standard-dialog";

export default {
  name: "CarTravel",
  components: {
    CarAirportTab,
    ENitinerary,
    overStandardDialog
  },
  data() {
    return {
      tabItem: "first",
      dialogVisible: false,
      overStandardVisible: false,
      // 行程创建方式回调
      handle: null,
      // 超标事由填写回调
      exceedStandardHandle: null,
      tripId: null
    };
  },
  created() {
    this.tripId = this.$route.query.tripId;
  },
  methods: {
    /**
     * 校验数据->校验超标->行程创建方式选择->新建行程、往选择的行程中加节点、添加节点放行程->保存数据
     * @param carData 用车数据实体
     */
    complete(carData) {
      this.$showLoading();
      carData.reserveHandle().then((response) => {
        this.$hideLoading();
        const { save, isExceedStandard } = response;
        // 处理超标
        const exceedStandardHandle = ((text) => {
          // 关闭超标事由弹框
          this.overStandardVisible = false;
          // 处理新建行程或者选择行程
          const handle = ((tripData) => {
            // 关联关联方式选择
            this.dialogVisible = false;
            this.$showLoading();
            // 保存数据
            save(tripData, text).then((response) => {
              this.$hideLoading();
              this.$router.push({
                path: "/business-travel/trip/tripDetail",
                query: { tripId: response }
              });
            }).catch((error) => {
              this.$hideLoading();
              this.$message.error(error.message);
            });
          });
          // 行程中添加节点
          if (this.tripId?.length > 0) {
            handle({ id: this.tripId });
          } else {
            this.handle = handle;
            // 选择创建方式 关联已有行程或者新建行程
            this.dialogVisible = true;
          }
        });
        if (isExceedStandard) {
          // 超标事由填写
          this.exceedStandardHandle = exceedStandardHandle;
          // 打开超标事由弹框
          this.overStandardVisible = true;
        } else {
          // 未超标
          exceedStandardHandle();
        }
      }).catch((error) => {
        this.$hideLoading();
        this.$message.error(error.message);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
  .car-travel {
    background: url("https://images.softfuxi.com/static/user_carbg.jpg") no-repeat;
    background-size: cover;
    position: relative;

    .car-form {
      position: absolute;
      right: 42px;
      top: 42px;
      width: 520px;
      background-color: rgb(60, 128, 197);
      border-radius: 4px 4px 4px;
      padding: 8px 8px 8px;

      .car-main {
        padding: 0 20px;
        background-color: #ffffff;
        ::v-deep .el-tabs__nav {
          width: 100%;
          text-align: center;
          .el-tabs__item {
            width: calc(100% / 2);
          }
        }
        ::v-deep .el-tabs__content {
          margin-top: 20px;
        }
      }
    }
  }
</style>
