<!--
 * @Author: Louis
 * @Date: 2019-08-28 09:56:26
 * @LastEditors: zhulin
 * @LastEditTime: 2021-07-09 10:04:14
 * @Description:
 -->
<template>
  <div class="en-flight">
    <div class="en-flight-container">
      <div v-if="tipsLabel !== ''" class="tips-label">
        {{ tipsLabel }}
      </div>
      <el-input
        v-model="input"
        :class="{ 'with-tips': tipsLabel !== '', 'show-right' : showRight}"
        readonly="readonly"
        :placeholder="placeholder"
        @click.native="clickQueryInfo"
      >
      </el-input>
      <myBooking v-if="showRight" type="flight" @selectData="updateValue"></myBooking>
    </div>
    <el-dialog
      v-if="showDialog"
      title="航班信息"
      width="700px"
      :visible.sync="showDialog"
    >
      <el-form label-position="right" label-width="60px" :model="flightForm">
        <el-form-item label="起飞时间" prop="date">
          <el-select v-model="flightForm.date" placeholder="请选择起飞时间">
            <el-option
              v-for="item in dateList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="航班号" prop="flight">
          <el-autocomplete
            v-model="flightForm.keywords"
            placeholder="请输入航班号，字母不区分大小写，如MU5569"
            :fetch-suggestions="searchFlightInfo"
            :trigger-on-focus="false"
            @select="selectFlight"
          >
          </el-autocomplete>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <en-button @click="confirmFlight">
          确定
        </en-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import dayjs from "dayjs";
import { debounce } from "lodash";
import { filterAirPortData } from "@/tools/cityDataHandle";
import { carService } from "@/api/businessTravel";
import myBooking from "../myBooking";
import { updateFlightInfoShowValue } from "../../tools";

export default {
  name: "EnFlight",
  components: {
    myBooking
  },
  props: {
    tipsLabel: {
      type: String,
      default: ""
    },
    showRight: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object
    },
    carData: {
      type: Object
    },
    placeholder: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showDialog: false,
      flightForm: {
        date: "",
        keywords: "",
        flightInfo: null
      }
    };
  },
  computed: {
    dateList() {
      const date = dayjs(new Date());
      const week = {
        1: "一",
        2: "二",
        3: "三",
        4: "四",
        5: "五",
        6: "六",
        7: "日"
      };
      const date1 = date;
      const date2 = date.add(1, "day");
      const date3 = date.add(2, "day");
      return [date1, date2, date3].map((item, index) => {
        const str = item.format("YYYY-MM-DD");
        if (index === 0) {
          return { id: str, name: `${str} 今天` };
        }
        return {
          id: str,
          name: `${str} 星期${week[item.day()]}`
        };
      });
    },
    input() {
      if (!this.value) {
        return "";
      }
      const date = this.carData.isPickup ? this.value.flightArrtimePlan : this.value.flightDeptimePlan;
      return `${this.value.flightNo} | ${dayjs(date).format("YYYY-MM-DD HH:mm")}`;
    }
  },
  methods: {
    /**
     * 查询航班信息
     * @param keyword 关键字
     * @param cb 回调
     */
    queryFlightInfo: debounce((date, keywords, cb) => {
      carService.queryflightInformation(date, keywords).then((response) => {
        cb(response);
      }).catch(() => {
        cb([]);
      });
    }, 500),
    // 搜索航班信息
    searchFlightInfo(keywords, cb) {
      if (keywords.length <= 2) {
        cb([]);
        return;
      }
      this.flightForm.flightInfo = null;
      this.queryFlightInfo(this.flightForm.date, keywords, (datas) => {
        updateFlightInfoShowValue(datas);
        cb(datas);
      });
    },
    /**
     * 显示弹框，默认今天
     */
    clickQueryInfo() {
      this.flightForm.date = this.dateList[0].id;
      this.flightForm.keywords = "";
      this.flightForm.flightInfo = null;
      this.showDialog = true;
    },
    /**
     * 确定选择航班信息
     */
    confirmFlight() {
      if (this.flightForm.flightInfo === null) {
        this.$message("请选择航班");
        return;
      }
      if (this.checkData(this.flightForm.flightInfo)) {
        this.$emit("change", this.flightForm.flightInfo);
        this.showDialog = false;
      }
    },
    // 校验是否提供机场服务
    checkData(data) {
      const code = this.carData.isPickup ? data.flightArrcode : data.flightDepcode;
      const datas = filterAirPortData(code);
      if (datas.length === 0) {
        this.$message("抱歉，该城市暂未开通接送机服务");
        return false;
      }
      return true;
    },
    /**
     * 选中航班数据
     * @param itemData 航班数据
     */
    selectFlight(itemData) {
      this.flightForm.flightInfo = itemData;
    },
    updateValue(data) {
      if (this.checkData(data)) {
        this.$emit("change", data);
        this.showMineList = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.en-flight ::v-deep {
  user-select: none;
  position: relative;
  display: flex;
  justify-content: flex-start;
  .en-flight-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    .tips-label {
      position: absolute;
      left: 12px;
      line-height: 12px;
      top: 13px;
      z-index: 1;
      font-size: 12px;
      font-weight: 400;
      color: #91a1b7;
    }

    .el-input {
      display: flex;
      justify-content: flex-start;
      line-height: 36px;
      height: 36px;
      width: 100%;

      input {
        height: 36px;
        padding-left: 72px;
        color: #333333;
      }
    }

    .show-right {
      input {
        padding-right: 100px;
      }
    }

  }

  .el-dialog__header {
    text-align: left;
  }
  .el-select,
  .el-autocomplete {
    width: 100%;
  }
}

</style>
