import dayjs from "dayjs";

/**
 * 更新显示信息
 * @param datas 航班数据
 * @returns {*}
 */
export function updateFlightInfoShowValue(datas) {
  datas.forEach((data) => {
    const flightDeptimePlan = dayjs(data.flightDeptimePlan).format("HH:mm");
    const flightArrtimePlan = dayjs(data.flightArrtimePlan).format("HH:mm");
    data.value = `${data.flightNo}${data.flightDep}${flightDeptimePlan} - ${data.flightArr}${flightArrtimePlan}`;
  });
  return datas;
}

// 接机使用时间选择数据
export function getTimeOptions() {
  return [
    {
      name: "飞机抵达后15分钟",
      value: 15
    },
    {
      name: "飞机抵达后30分钟",
      value: 30
    },
    {
      name: "飞机抵达后45分钟",
      value: 45
    },
    {
      name: "飞机抵达后60分钟",
      value: 60
    },
    {
      name: "飞机抵达后90分钟",
      value: 90
    },
    {
      name: "飞机抵达后120分钟",
      value: 120
    }
  ];
}

// 100舒适型，400商务型, 200豪华型, 500优选型
export function getCarTypeOptions() {
  return [
    {
      name: "舒适",
      value: "100"
    },
    {
      name: "商务",
      value: "400"
    },
    {
      name: "豪华",
      value: "200"
    }
  ];
}
