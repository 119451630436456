<!--
 * @Author: Louis
 * @Date: 2019-08-26 14:05:32
 * @LastEditors: Louis
 * @LastEditTime: 2019-08-29 15:25:47
 * @Description:
 -->
<template>
  <el-popover
    placement="bottom-end"
    width="500"
    popper-class="en-address-popper"
    v-model="visible"
    trigger="manual">
    <el-input
      ref="input"
      v-model="keywords"
      placeholder="如需缩小范围，请输入更多详细内容"
      @focus="focus1"
      :autofocus="true"
      @blur="blur1"
    >
    </el-input>
    <div class="en-address-list-main">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="en-address-item"
        @click="selectAddress(item)"
      >
        <div class="en-address-basic">
          <div class="en-address-name">
            {{ item.name }}
          </div>
          <div class="en-address-details">
            {{ item.detail }}
          </div>
        </div>
        <div class="en-address-city-info">
          {{ item.areaName }}
        </div>
      </div>
    </div>
    <div
      slot="reference"
      class="en-address"
      ref="input"
    >
      <div class="en-address-container">
        <el-amap :vid="'amap-vue'" :plugin="['Autocomplete', 'PlaceSearch']" ref="map" />
        <div
          v-if="tipsLabel !== ''"
          class="tips-label"
        >
          {{ tipsLabel }}
        </div>
        <el-input
          @click.native="focus"
          v-model="valueInfo.name"
          readonly="readonly"
          :placeholder="placeholder"
          :class="{'self-define':showRight,'focus':visible}"
          @blur="blur"
        >
        </el-input>
        <myBooking v-if="showRight" type="hotel" @selectData="selectHotel"></myBooking>
      </div>
    </div>
  </el-popover>
</template>

<script>
import { debounce } from "lodash";
import myBooking from "../myBooking";

export default {
  name: "EnAddress",
  components: {
    myBooking
  },
  props: {
    tipsLabel: {
      type: String,
      default: ""
    },
    value: {
      type: Object,
      default: () => ({})
    },
    showRight: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      list: [],
      valueInfo: {
        name: "",
        details: ""
      },
      visible: false,
      isInputFocus: false,
      keywords: ""
    };
  },
  watch: {
    keywords(val) {
      if (val.length > 0) {
        this.filterList(this.keywords, ((data) => {
          if (this.visible) {
            this.list = data;
          }
        }));
      } else {
        this.list = [];
      }
    },
    value(nVal) {
      this.valueInfo = nVal;
    }
  },
  methods: {
    /**
     * @description 聚焦输入框
     */
    focus() {
      this.visible = !this.visible;
      this.keywords = "";
      this.list = [];
    },
    blur() {
      setTimeout(() => {
        if (!this.isInputFocus) {
          this.visible = false;
        }
      }, 10);
    },
    /**
     * @description 输入框失去焦点/点击外部，关闭面板
     */
    blur1() {
      this.visible = false;
      this.isInputFocus = false;
    },
    focus1() {
      this.isInputFocus = true;
    },
    /**
     * @description 搜索过滤
     */
    filterList: debounce((keywords, resolve) => {
      // eslint-disable-next-line no-undef
      const map = AMap;
      map.plugin(["AMap.Autocomplete", "AMap.PlaceSearch"], () => {
        const autocomplete = new map.Autocomplete({ datatype: "poi" });
        autocomplete.search(keywords, (status, result) => {
          if (status === "no_data" || status === "error") {
            resolve([]);
          } else {
            const data = [];
            const placeSearch = new map.PlaceSearch({ extensions: "all" });
            const all = result.tips.map((item) => new Promise((resolve) => {
              placeSearch.getDetails(item.id, (status, result) => {
                if (status === "no_data" || status === "error") {
                  item.detail = item.address;
                  item.areaName = "中国";
                } else if (result?.poiList?.pois?.length > 0) {
                  const detailData = result?.poiList?.pois[0];
                  item.cityname = detailData.cityname;
                  item.adname = detailData.adname;
                  item.address = detailData.address;
                  item.detail = `${item.cityname}-${item.adname}${item.address.length > 0 ? `-${item.address}` : ""}`;
                  item.areaName = `${detailData.cityname.replace("市", "")}，中国`;
                  data.push(item);
                }
                resolve();
              });
            }));
            Promise.all(all).then(() => {
              resolve(data);
            });
          }
        });
      });
    }, 500),
    /**
     * @description 选中数据，进行回传并回显
     * @param itemData
     */
    selectAddress(itemData) {
      this.$emit("input", itemData);
      this.visible = false;
    },
    selectHotel(data) {
      console.log(data);
    }
  }
};
</script>

<style lang="scss">
  .en-address {
    user-select: none;
    position: relative;
    display: flex;
    justify-content: flex-start;

    .en-address-container {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      position: relative;

      .tips-label {
        position: absolute;
        left: 12px;
        line-height: 12px;
        top: 13px;
        z-index: 1;
        font-size: 12px;
        font-weight: 400;
        color: #91a1b7;
      }

      .el-input {
        display: flex;
        justify-content: flex-start;
        line-height: 36px;
        height: 36px;
        width: 100%;

        &.self-define {
          input {
            padding: 0 100px 0 72px;
          }
        }

        &.focus {
          .el-input__inner {
            border-color: #409EFF;
          }
        }

        input {
          height: 36px;
          padding: 0 72px;
          color: #333333;
        }
      }
    }
  }
  .en-address-popper {
    width: 500px;
    padding: 0;
    /deep/ .el-input__inner {
      border: none;
      border-bottom: 1px solid #E8ECF2 !important;
      height: 32px;
    }
    .en-address-list-main {
      max-height: 260px;
      overflow-y: auto;

      .en-address-item {
        height: 52px;
        display: flex;
        justify-content: flex-start;
        padding: 10px 11px 10px;
        border-bottom: 1px solid #e8ecf2;

        .en-address-basic {
          width: calc(100% - 120px);
          text-align: left;

          .en-address-name {
            font-size: 12px;
            line-height: 12px;
            color: #333333;
            width: 100%;
          }

          .en-address-details {
            width: 100%;
            color: #b9c4d2;
            font-size: 12px;
            line-height: 12px;
            margin-top: 6px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        .en-address-city-info {
          width: 100%;
          text-align: right;
          font-size: 12px;
          color: #333333;
          font-weight: 400;
          line-height: 12px;
          padding: 10px 0;
        }
      }
    }
  }
</style>
