import dayjs from "dayjs";
import store from "@/store";
import { carService, tripService } from "@/api/businessTravel";
import { huoliCityData } from "@/tools/cityDataHandle";

const userInfo = store.getters.userInfo;

export const CarProdType = {
  pickup: 1, // 接机
  send: 2 // 送机
};

export class CarData {
  constructor(isPickup, huoli = true) {
    // 航班数据
    this.flightInfo = null;
    // 选择的用车时间
    this.useTime = "";
    // 出发地数据
    this.fromLocationInfo = null;
    // 目的地数据
    this.toLocationInfo = null;
    // 用车人员数据
    this.usePersonInfo = {
      name: userInfo.name,
      mobileNo: userInfo.mobileNo
    };
    // 100舒适型，400商务型, 200豪华型, 500优选型
    this.carType = "100";
    // 价格
    this.price = "0.00";
    // 提交需要的数据
    this.data = {};
    // 201:专车  301:快车
    this.data.rule = huoli ? "201" : "301";
    if (huoli) {
      // 专车服务类型
      this.data.prodType = isPickup ? CarProdType.pickup : CarProdType.send;
    }
    // 0:滴滴.1伙力专车
    this.data.carType = huoli ? "1" : "0";
    // 0:实时单 1:预约单
    this.data.type = 1;
    // 价格预估数据
    this.priceInfo = null;
  }

  getSubmitData(nodeData = false) {
    const param = { ...this.data };
    param.telePhone = this.usePersonInfo.mobileNo;
    param.requireLevel = this.carType;
    param.flat = this.fromLocationInfo.location.lat;
    param.flng = this.fromLocationInfo.location.lng;
    param.tlat = this.toLocationInfo.location.lat;
    param.tlng = this.toLocationInfo.location.lng;

    const cityname = this.fromLocationInfo.cityname.replace("市", "");
    const cityItem = huoliCityData.filter((item) => item.cityName.indexOf(cityname) !== -1)[0];
    if (cityItem) {
      param.city = cityItem.cityCode;
    }
    if (this.data.prodType === CarProdType.pickup) {
      const date = dayjs(this.flightInfo.flightArrtimePlan);
      date.add(this.useTime, "minute");
      param.departureTime = date.format("YYYY-MM-DD hh:mm:ss");
      param.depotCode = this.flightInfo.flightDepcode;
      param.arrCode = this.flightInfo.flightArrcode;
    } else if (this.data.prodType === CarProdType.send) {
      const date = dayjs(this.useTime);
      param.departureTime = date.format("YYYY-MM-DD hh:mm:ss");
      param.depotCode = this.flightInfo.flightArrcode;
      param.arrCode = this.flightInfo.flightDepcode;
    }
    if (nodeData) {
      param.arrivalDate = this.flightInfo.flightDate;

      param.estiMate = this.priceInfo?.price;
      if (this.priceInfo?.priceDetail) {
        param.priceDetail = JSON.stringify(this.priceInfo.priceDetail);
      }
      param.enCityCode = cityItem.enCityCode;
      param.flightDate = this.flightInfo.flightDate;
      param.flightNo = this.flightInfo.flightNo;
      param.flightAddress = `${this.flightInfo.flightDep},${this.flightInfo.flightArr}`;
      param.totalAmount = this.priceInfo?.price;
      param.dynamicMd5 = this.priceInfo?.dynamicMd5;

      param.passengerId = userInfo.userId || userInfo.id;
      param.passengerName = userInfo.name;
      param.passengerPhone = userInfo.mobileNo;
      param.cityName = this.fromLocationInfo.cityname;

      param.startName = this.fromLocationInfo.name;
      param.startAddress = this.fromLocationInfo.address;
      param.endName = this.toLocationInfo.name;
      param.endAddress = this.fromLocationInfo.address;
      param.id = "";
      param.tripNodeType = "003";
    }
    return param;
  }

  /**
   * 必填校验数据
   * @returns {Error}
   */
  checkRequiredData() {
    if (!this.flightInfo) {
      return "请选择航班号";
    }
    if (this.useTime?.length === 0) {
      return "请选择用车时间";
    }
    if (!this.fromLocationInfo) {
      return "请选择出发地";
    }
    if (!this.toLocationInfo) {
      return "请选择目的地";
    }
    if (!this.usePersonInfo) {
      return "请选择用车人员";
    }
    if (this.carType?.length === 0) {
      return "请选择车型";
    }
    return null;
  }

  // 校验错误
  checkData() {
    const error = this.checkRequiredData();
    if (error) {
      return error;
    }
    if (this.huoliProdType === CarProdType.pickup) {
      const date = dayjs(this.flightInfo.flightArrtimePlan);
      if (date.isValid() && date.unix() < dayjs().unix()) {
        return "您输入的航班到达时间已过，请重新输入";
      }
    }
    if (this.huoliProdType === CarProdType.send) {
      const date = dayjs(this.useTime);
      if (date.isValid() && date.unix() < dayjs().unix()) {
        return "您的用车时间早于当前时间，请重新输入";
      }
    }
    return null;
  }

  // 是否是接机
  get isPickup() {
    return this.huoliProdType === CarProdType.pickup;
  }

  // 伙力用车服务类型
  get huoliProdType() {
    return this.data.prodType;
  }

  // 预估价格
  queryPriceInfo() {
    return carService.queryPriceInfo(this.getSubmitData()).then((response) => {
      this.priceInfo = response;
      this.price = response.price;
    });
  }

  // 重新预估价格
  againEstimatedPrice(start, end) {
    if (this.checkRequiredData()) {
      return;
    }
    this.priceInfo = null;
    start();
    this.queryPriceInfo().then(() => {
      end();
    }).catch(() => {
      end();
    });
  }

  // 校验是否预估了价格，没有先预估价格
  checkPriceInfo() {
    return new Promise((resolve, reject) => {
      if (this.priceInfo) {
        resolve();
        return;
      }
      carService.queryPriceInfo(this.getSubmitData()).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  }

  /**
   * 预定数据处理
   * @returns {Promise<void>}
   */
  reserveHandle() {
    return new Promise((resolve, reject) => {
      const message = this.checkData();
      if (message) {
        reject(Error(message));
        return;
      }
      const data = this.getSubmitData(true);
      this.checkCarData(data).then(() => {
        this.checkCarDataSupplement(data).then((response) => {
          // 更新事由，保存数据
          const save = ((tripData, text) => {
            // 更新超标事由
            if (response) {
              data.standardFlag = "001";
              data.standardMemo = "";
            } else {
              data.standardFlag = "000";
              data.standardMemo = text;
            }
            return this.saveTripData(data, tripData);
          });
          if (response) {
            resolve({
              save,
              isExceedStandard: true
            });
          } else {
            resolve({ save, isExceedStandard: false });
          }
        }).catch((error) => {
          reject(error);
        });
      }).catch((error) => {
        reject(error);
      });
    });
  }

  // 校验数据
  checkCarData(data) {
    return new Promise((resolve, reject) => {
      this.checkPriceInfo().then(() => {
        carService.checkCarData(data).then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      }).catch((error) => {
        reject(error);
      });
    });
  }

  // 标准校验
  checkCarDataSupplement(data) {
    return new Promise((resolve, reject) => {
      const param = { tripNodList: JSON.stringify([data]), tripNodeType: "003", type: "003" };
      tripService.queryCheckSupplement(param).then((reponse) => {
        // reponse.result.isExcess = "000";
        // reponse.result.standardMode = "001";
        if (reponse.result.isExcess === "000") {
          if (reponse.result.standardMode === "000") {
            reject(Error("行程超标, 禁止提交"));
            return;
          }
          // 超标填写事由
          resolve(true);
        } else {
          resolve(false);
        }
      }).catch((error) => {
        reject(error);
      });
    });
  }

  // 保存数据到行程中
  saveTripData(data, tripData) {
    const param = { addTripNodeList: JSON.stringify([data]) };
    if (tripData) {
      param.id = tripData.id || "";
    }
    return tripService.querySaveTripNode(param).then((response) => response.id || param.id);
  }

  /**
   * 更新地址和用车时间
   * @param map 地图实体
   * @param resolve 回调，失败或者成功都会回调
   */
  updateFlightAddressAndTime(map, resolve) {
    if (this.isPickup) {
      if (this.useTime.length === 0) {
        this.useTime = 15;
      }
    } else {
      this.useTime = dayjs(this.flightInfo.flightDeptimePlan).subtract(3, "hour").format("YYYY-MM-DD HH:mm");
    }
    // 更新地址，搜索机场名字获取地址信息
    const flightArr = this.isPickup ? this.flightInfo.flightArr : this.flightInfo.flightDep;
    let flightTerminal = this.isPickup ? this.flightInfo.flightTerminal : this.flightInfo.flightHTerminal;
    if (flightArr.length === 0) {
      resolve();
      return;
    }
    if (flightTerminal?.length > 0) {
      flightTerminal = `${flightTerminal}航站楼`;
    }
    const keyword = `${flightArr}${flightTerminal || ""}`;
    this.getLocationData(map, keyword).then((location) => {
      if (this.isPickup) {
        this.fromLocationInfo = location;
      } else {
        this.toLocationInfo = location;
      }
      resolve();
    }).catch(() => {
      resolve();
    });
  }

  /**
   * 通过关键字获取地址信息
   * @param map 地图实体
   * @param keyword 关键字
   * @returns {Promise<unknown>}
   */
  getLocationData(map, keyword) {
    return new Promise((resolve, reject) => {
      map.plugin(["AMap.Autocomplete", "AMap.PlaceSearch"], () => {
        const autocomplete = new map.Autocomplete({ datatype: "poi" });
        autocomplete.search(keyword, (status, result) => {
          if (!(status === "complete" && result.info === "OK")) {
            reject();
            return;
          }
          const placeSearch = new map.PlaceSearch({ extensions: "all" });
          const item = result.tips[0];
          placeSearch.getDetails(item.id, (status, result) => {
            if (!(status === "complete" && result.info === "OK")) {
              reject();
              return;
            }
            const detailData = result?.poiList?.pois[0];
            item.cityname = detailData.cityname;
            item.adname = detailData.adname;
            item.address = detailData.address;
            resolve(item);
          });
        });
      });
    });
  }
}
