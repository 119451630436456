<template>
  <el-dialog
    title="选择人员"
    class="contact"
    :show-close="false"
    :visible.sync="dialogVisible"
    append-to-body
  >
    <div class="contact-body-contain">
      <span class="title">选择人员</span>
      <div @click="close">
        <en-icon
          name="guanbi-danchuang"
          class="close-icon"
          size="14px"
          :style="{ color: '#A9B5C6' }"
        ></en-icon>
      </div>
      <div class="content-contain">
        <el-tabs v-model="activeTab">
          <el-tab-pane label="常用" name="first">
            <div class="often-contain">
              <el-input
                v-model="keyword"
                @change="searchPersonDatas"
                size="mini"
                placeholder="搜索关键字"
                suffix-icon="el-icon-search"
                class="contact-search"
              />
              <div class="section-title" v-if="showHistoryData">
                选择历史
              </div>
              <div class="person-content" v-en-loading="loading">
                <template v-for="(item, index) in datas">
                  <div
                    class="person-content-item"
                    :key="index"
                    @click="click(item)">
                    <div class="user-Infos">
                      <en-user-logo :user-name="item.name" size="30"></en-user-logo>
                      <div class="name">{{ item.name }}</div>
                    </div>
                    <div class="mobile">
                      {{ item.mobile }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="新增" name="second">
            <div class="add-contain">
              <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="80px"
              >
                <el-form-item label="姓名" prop="name">
                  <el-input
                    v-model="form.name"
                    clearable
                    placeholder="请输入姓名"
                  ></el-input>
                </el-form-item>
                <el-form-item label="手机号码" prop="mobile">
                  <el-input
                    v-model="form.mobile"
                    clearable
                    placeholder="请输入手机号码"
                  ></el-input>
                </el-form-item>
                <en-button
                  size="small"
                  type="blue"
                  class="confirm-button"
                  @click="complete"
                >
                  确定
                </en-button>
              </el-form>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { carService } from "@/api/businessTravel";

export default {
  name: "EnContact",
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      keyword: "",
      activeTab: "first",
      form: {
        name: "",
        mobile: ""
      },
      showHistoryData: true,
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur"
          }
        ],
        mobile: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur"
          }
        ]
      },
      datas: [],
      historyDatas: [],
      loading: false
    };
  },
  mounted() {
    this.queryHistoryPerson();
  },
  methods: {
    queryHistoryPerson() {
      this.loading = true;
      carService.queryHistoryPerson().then((response) => {
        this.historyDatas = response;
        this.updateData();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    click(data) {
      this.$emit("complete", data);
    },
    complete() {
      this.$refs.form.validate(() => {
        if (this.form.mobile.length === 0 || this.form.name.length === 0) {
          return;
        }
        if (this.form.mobile.length !== 11) {
          this.$message("请输入正确的手机号码");
          return;
        }
        const obj = {
          mobile: this.form.mobile,
          name: this.form.name
        };
        this.$emit("complete", obj);
      });
    },
    close() {
      this.$emit("close");
    },
    updateData(datas) {
      if (this.keyword.length > 0) {
        this.datas = datas;
        this.showHistoryData = false;
      } else {
        this.datas = this.historyDatas;
        this.showHistoryData = true;
      }
    },
    searchPersonDatas() {
      if (this.keyword.length === 0) {
        this.updateData();
      } else {
        this.loading = true;
        carService.queryPerson(this.keyword).then((response) => {
          this.updateData(response);
          this.loading = false;
        }).catch(() => {
          this.updateData([]);
          this.loading = false;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .contact {
    .contact-body-contain {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 900px;
      height: 540px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      .title {
        position: absolute;
        left: 20px;
        top: 20px;
        height: 14px;
        font-size: 14px;
        line-height: 14px;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        text-align: left;
        color: rgba(51, 51, 51, 1);
      }
      .close-icon {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 14px;
        height: 14px;
      }
      .content-contain {
        margin-top: 42px;
        height: calc(100% - 42px);
        // padding:0 20px;
        text-align: left;
        /deep/ .el-tabs__nav-scroll{
          padding-left:20px;
        }
        & ::v-deep .el-tabs__nav {
          text-align: center;
          .el-tabs__item {
            width: 120px;
          }
        }
        & ::v-deep .el-tabs__content {
          margin-top: 0;
        }
        // 常用
        .often-contain {
          margin: 12px 20px 16px;
          border: 1px solid #e8ecf2;
          border-radius: 4px;

          ::v-deep .el-input__inner {
            width: 100%;
            padding-left: 12px;
            padding-right: 22px;
          }

          .contact-search {
            width: calc(100% - 22px);
            margin: 11px 11px 10px 11px;
          }
          .section-title {
            width: 100%;
            height: 32px;
            background: rgba(246, 250, 253, 1);
            border-top: 1px solid #e8ecf2;
            border-bottom: 1px solid #e8ecf2;

            font-size: 12px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: rgba(99, 108, 120, 1);
            line-height: 32px;
            padding-left: 12px;
          }

          .person-content {
            height: 345px;
            .person-content-item {
              padding-left: 12px;
              display: flex;
              align-items: center;
              height: 40px;
              position: relative;
              .user-Infos {
                display: flex;
                align-items: center;
              }
              .name {
                margin-left: 12px;
              }
              .mobile {
                left: 50%;
                position: absolute;
                text-align: left;
              }
              .name,
              .mobile {
                color: #636B77;
                font-size: 12px;
              }
              &:hover {
                background-color: #F5F7FB;
                .name,
                .mobile {
                  color: #179DD9;
                }
              }
            }
          }
        }
        // 新增
        .add-contain {
          height: calc(540px - 40px - 20px - 15px - 50px);
          margin: 20px 20px 15px;
          ::v-deep .el-form-item {
            margin-bottom: 16px;
          }
          ::v-deep .el-form-item__content {
            margin-left: 72px !important;
          }
          ::v-deep .el-form-item__label {
            width: 72px !important;
            text-align: right;
            font-size: 12px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
          }
          ::v-deep .el-form-item__label:before {
            display: none;
          }
          ::v-deep .el-input__inner {
            // width: 798px;
            padding-left: 12px;
          }
          ::v-deep .el-input__icon {
            line-height: 32px !important;
          }
          .confirm-button {
            position: absolute;
            bottom: 16px;
            right: 20px;
            width: 72px;
            height: 32px;
            background-color: #4895df;
            border-radius: 4px;
            font-size: 12px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: #fff;
            line-height: 12px;
          }
        }
      }
    }
    ::v-deep .el-dialog__header {
      padding: 0px;
      margin: 0px;
      height: 0px;
    }
    ::v-deep .el-dialog__body {
      padding: 0px;
      margin: 0px;
    }
  }
</style>
