<template>
  <div class="en-personal">
    <div class="en-personal-container">
      <div
        v-if="tipsLabel !== ''"
        class="tips-label"
      >
        {{ tipsLabel }}
      </div>
      <el-input
        v-model="value.name"
        :class="{'with-tips': tipsLabel !== ''}"
        readonly="readonly"
        :placeholder="placeholder"
        @focus="focus"
      >
      </el-input>
    </div>
    <contact
      v-if="contactShow"
      @close="contactShow = false"
      :dialog-visible="contactShow"
      @complete="complete"
    >
    </contact>
  </div>
</template>

<script>
import contact from "./contact";

export default {
  name: "EnPersonal",
  components: { contact },
  props: {
    tipsLabel: {
      type: String,
      default: ""
    },
    value: {
      type: Object,
      default: () => ({})
    },
    placeholder: {
      type: String,
      default: "请选择"
    }
  },
  data() {
    return {
      contactShow: false
    };
  },
  methods: {
    focus() {
      this.contactShow = true;
    },
    complete(res) {
      this.$emit("input", res);
      this.contactShow = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  .en-personal {
    user-select: none;
    position: relative;
    display: flex;
    justify-content: flex-start;

    .en-personal-container {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      position: relative;

      .tips-label {
        position: absolute;
        left: 12px;
        line-height: 12px;
        top: 13px;
        z-index: 1;
        font-size: 12px;
        font-weight: 400;
        color: #91a1b7;
      }

      ::v-deep .el-input {
        display: flex;
        justify-content: flex-start;
        line-height: 36px;
        height: 36px;
        width: 100%;

        input {
          height: 36px;
          padding: 0 72px;
          color: #333333;
        }
      }
    }
  }
</style>
